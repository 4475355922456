import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Facebook, Instagram, LinkedIn, Phone, Email, LocationOn } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    footer: {
        background: 'linear-gradient(135deg,#ffcf00,#00c6ff)',
        color: '#fff',
        padding: theme.spacing(4, 0),
    },
    footerContent: {
        maxWidth: 1200,
        margin: '0 auto',
        padding: theme.spacing(0, 2),
        color: '#000',
    },
    companyInfo: {
        marginBottom: theme.spacing(4),
        color: '#000',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    socialIcons: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
        },
    },
    icon: {
        color: '#000',
        fontSize: 28,
        margin: theme.spacing(0, 1),
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
    rightsReserved: {
        marginTop: theme.spacing(2),
        color: '#000',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            alignItems: 'center',
            marginLeft: 0,
        },
    },
    boldText: {
        fontWeight: 'bold',
    },
    boldTextContactUs: {
        fontWeight: 'bold',
        marginRight: "210px",
    },
    contactItem: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    contactIcon: {
        marginRight: theme.spacing(2),
        color: '#000',
    },
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <div className={classes.footerContent}>
                <Grid container spacing={4} justifyContent="space-between">
                    <Grid item xs={12} sm={6} className={classes.companyInfo}>
                        <Typography variant="h6" gutterBottom className={classes.boldText}>
                            Connect with us on social media
                        </Typography>
                        <div className={classes.socialIcons}>
                            <a
                                href="https://www.facebook.com/people/Digiaura-Marketing-Solutions/61562193635075/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.icon}
                                aria-label="Facebook"
                            >
                                <Facebook />
                            </a>
                            <a
                                href="https://www.instagram.com/digiaura_marketing_solutions?igsh=MXF0cmtibTQwbnhoNQ%3D%3D&utm_source=qr"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.icon}
                                aria-label="Instagram"
                            >
                                <Instagram />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/digiaura-marketing-solutions"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.icon}
                                aria-label="LinkedIn"
                            >
                                <LinkedIn />
                            </a>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} container justifyContent="flex-end">
                        <Box className={classes.contactInfo}>
                            <Typography variant="h6" gutterBottom align="left" className={classes.boldTextContactUs}>
                                Contact Us
                            </Typography>
                            <address className={classes.contactItem}>
                                <LocationOn className={classes.contactIcon} aria-hidden="true" />
                                <Typography variant="body1" align="left">
                                    Flat No. 401-402, Shri Manomay Complex,<br />
                                    Opp. MH31 Restaurant,<br />
                                    Koradi Naka, Nagpur-440030
                                </Typography>
                            </address>
                            <div className={classes.contactItem}>
                                <Phone className={classes.contactIcon} aria-hidden="true" />
                                <Typography variant="body1" align="left">
                                    <a href="tel:+918329691567">+(91) 8329691567</a>, <a href="tel:+917972607606" >+(91) 7972607606</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </Typography>
                            </div>
                            <div className={classes.contactItem}>
                                <Email className={classes.contactIcon} />
                                <Typography variant="body1" align="left">
                                    <a href="mailto:info.digiaura@gmail.com" >info.digiaura@gmail.com</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </Typography>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
                <Typography variant="body2" align="center" className={classes.rightsReserved}>
                    &copy; {new Date().getFullYear()} DigiAura Marketing Agency. All rights reserved.
                </Typography>
            </div>
        </footer>
    );
};

export default Footer;
