import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Container,
    Dialog,
    DialogContent,
    makeStyles,
    IconButton,
    Chip,
} from '@material-ui/core';
import { motion } from 'framer-motion';
import CloseIcon from '@material-ui/icons/Close';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import fetchPhotos from '../client-api/services/pexels'; // Adjust the path as needed
import GauravImage from '../assets/images/Gaurav.JPG';
import PranavImage from '../assets/images/Pranav.jpg';
import UmaImage from '../assets/images/UmaImage.jpg';
import AboutUs from '../assets/images/aboutUs.webp';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useInView } from 'react-intersection-observer';

const useStyles = makeStyles((theme) => ({
    aboutSection: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(4),
        background: 'linear-gradient(135deg, #ffcf00, #00c6ff)',
        paddingBottom: theme.spacing(10),
        borderRadius: theme.shape.borderRadius,
    },
    imageContainer: {
        position: 'relative',
        width: '100%',
        marginTop:"75px",
        paddingTop: '80%',
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius,
        border: '2px solid white', // Add border here
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'transform 0.5s ease',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
    textSection: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        color: '#000000',
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1),
            fontSize: '2rem',
            color: '#000000',
        },
    },
    paragraph: {
        marginBottom: theme.spacing(2),
        color: '#000000',
    },
    successList: {
        listStyle: 'none',
        padding: 0,
        color: '#000000',
        '& li': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
            fontSize: '1.1rem',
        },
        '& li:before': {
            content: '"✔"',
            color: '#ffcf00',
            marginRight: theme.spacing(1),
            fontSize: '1.5rem',
        },
    },
    chipContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        flexWrap: 'wrap',
        justifyContent: 'left',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    chip: {
        backgroundColor: '#000000',
        color: '#ffffff',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#333333',
        },
    },
    dialog: {
        backdropFilter: 'blur(5px)',
        '& .MuiPaper-root': {
            borderRadius: theme.shape.borderRadius,
        },
    },
    dialogContent: {
        position: 'relative',
        padding: theme.spacing(2),
        textAlign: 'center',
        overflowY: 'auto', // Enable vertical scrolling
        maxHeight: 'calc(100vh - 200px)', // Limit max height to fit within the viewport
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    memberImageCircular: {
        width: '100%',
        height: 'auto',
        borderRadius: '50%',
        marginBottom: theme.spacing(2),
        border: '2px solid white', // Add white circular border
        transition: 'transform 0.5s ease',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
    memberImageSquare: {
        width: '100%',
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '400px',
        borderRadius: '0%',
        marginBottom: theme.spacing(2),
    },
    teamImage: {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        objectFit: 'cover',
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        border: '2px solid white', // Add white circular border
    },
    teamContainer: {
        marginLeft: "150px",
        display: 'flex',
        justifyContent: 'center', // Center items horizontally
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    socialIcons: {
        fontSize: '2rem',
        margin: theme.spacing(0, 1),
        color: '#000000',
        '&:hover': {
            color: '#333333',
        },
    },
}));

const teamMembers = [
    {
        name: 'Pranav Bhagat',
        image: PranavImage,
        description: 'Social Media Manager',
        linkedin: 'https://www.linkedin.com/in/pranavmbhagat/',
    },
    {
        name: 'Gaurav Chaudhary',
        image: GauravImage,
        description: 'Web Designer, SEO Optimization',
        linkedin: 'https://www.linkedin.com/in/gaurav-chaudhary-029769170',
    },
    {
        name: 'Uma Jahagirdar',
        image: UmaImage,
        description: 'Content Marketer',
        linkedin: 'https://www.linkedin.com/in/uma-jahagirdar-382170251',
    }
];

const AboutSection = () => {
    const classes = useStyles();
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);
    const [images, setImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    // useInView hook to detect when the section is in view
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 0.1, // Trigger when 50% of the section is visible
    });

    useEffect(() => {
        const fetchAndSetPhotos = async () => {
            try {
                const digitalMarketingPhotos = await fetchPhotos('business team', 100); // Adjust parameters as needed
                const landscapeImages = digitalMarketingPhotos.map(photo => photo.src.large2x);
                setImages(landscapeImages);
                setIsLoading(false); // Set loading state to false after images are fetched
            } catch (error) {
                console.error('Error fetching photos:', error);
                setIsLoading(false); // Set loading state to false on error
            }
        };

        fetchAndSetPhotos();

        // Change image every 10 seconds (10000 milliseconds)
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 10000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [images.length]); // Dependency on images.length to re-trigger effect when images change

    const handleChipClick = (member) => {
        setSelectedMember(member);
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
        setSelectedMember(null);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Number of slides to show at a time
        slidesToScroll: 1, // Number of slides to scroll at a time
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true, // Enable center mode for carousel
                   // centerPadding: '40px', // Adjust padding as needed
                },
            },
        ],
    };

    return (
        <Container className={classes.aboutSection} maxWidth={false}>
            <Grid container spacing={4} alignItems="stretch">
                <Grid item xs={12} md={6}>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                        transition={{ duration: 1 }}
                        className={classes.textSection}
                        ref={ref} // Attach ref to the element you want to observe
                    >
                        <Typography variant="h3" className={`${classes.sectionTitle}`}>
                            <BusinessIcon />
                            About Us
                        </Typography>
                        <Typography variant="body1" className={classes.paragraph}>
                            Welcome to DigiAura, your trusted partner in digital marketing solutions. We specialize
                            in helping businesses grow their online presence and achieve their marketing goals.
                        </Typography>
                        <Typography variant="h5" className={classes.sectionTitle}>
                            <WorkOutlineIcon />
                            Who We Are
                        </Typography>
                        <Typography variant="body1" className={classes.paragraph}>
                            At DigiAura, we are a team of passionate digital marketers, designers, and strategists
                            dedicated to delivering results-driven services. Our expertise spans across various
                            digital marketing domains, ensuring that we provide comprehensive solutions tailored to your needs.
                        </Typography>
                        <Typography variant="h5" className={classes.sectionTitle}>
                            <CheckCircleOutlineIcon />
                            Why Choose Us
                        </Typography>
                        <ul className={classes.successList}>
                            <li>Proven track record of successful digital marketing campaigns.</li>
                            <li>Expert team with in-depth knowledge of the latest marketing trends and technologies.</li>
                            <li>Customized strategies that align with your business objectives and target audience.</li>
                        </ul>
                        <Typography variant="h5" className={classes.sectionTitle}>
                            <AssignmentIcon />
                            Our Mission
                        </Typography>
                        <Typography variant="body1" className={classes.paragraph}>
                            Our mission is to empower businesses with innovative digital marketing strategies that
                            drive growth and create lasting value. We strive to deliver exceptional results through
                            our commitment to excellence, creativity, and customer satisfaction.
                        </Typography>
                    </motion.div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={inView ? { opacity: 1 } : { opacity: 0 }}
                        transition={{ duration: 1, delay: 0.5 }}
                    >
                        <div className={classes.imageContainer}>
                            {isLoading ? (
                                <Typography variant="body1">Loading...</Typography>
                            ) : images.length > 0 ? (
                                <img
                                    src={images[currentImageIndex]}
                                    alt="Digital Marketing"
                                    className={classes.image}
                                />
                            ) : (
                                <img
                                    src={AboutUs}
                                    alt="About Us Image"
                                    className={classes.image}
                                />
                            )}
                        </div>
                    </motion.div>
                </Grid>
                <Grid item xs={12} className={classes.textSection}>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={inView ? { opacity: 1 } : { opacity: 0 }}
                        transition={{ duration: 1, delay: 0.5 }}
                        className={classes.textSection}
                    >
                        <Typography variant="h5" className={`${classes.sectionTitle}`}>
                            <PeopleIcon />
                            Meet Our Team
                        </Typography>
                        <Slider {...settings}>
                            {teamMembers.map((member, index) => (
                                <div key={index} className={classes.teamContainer}>
                                    <img
                                        src={member.image}
                                        alt={member.name}
                                        className={classes.teamImage}
                                        onClick={() => handleChipClick(member)}
                                    />
                                    <Chip
                                        label={member.name}
                                        className={classes.chip}
                                        onClick={() => handleChipClick(member)}
                                        style={{marginLeft:"15px"}}
                                    />
                                </div>
                            ))}
                        </Slider>
                        <Dialog open={openPopup} onClose={handleClosePopup} className={classes.dialog}>
                            <DialogContent
                                className={classes.dialogContent}
                                style={{ display: openPopup ? 'block' : 'none' }} // Conditionally control display
                            >
                                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClosePopup}>
                                    <CloseIcon />
                                </IconButton>
                                {selectedMember && (
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src={selectedMember.image} alt={selectedMember.name} className={classes.memberImageSquare} />
                                        <Typography variant="h5" className={classes.sectionTitle}>
                                            {selectedMember.name}
                                        </Typography>
                                        <div className={classes.scrollableContainer}>
                                            <Typography variant="body1" className={classes.paragraph} style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
                                                {selectedMember.description}
                                            </Typography>
                                            <div>
                                                <IconButton
                                                    aria-label="LinkedIn"
                                                    className={classes.socialIcons}
                                                    href={selectedMember.linkedin}
                                                    target="_blank"
                                                >
                                                    <LinkedInIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </DialogContent>
                        </Dialog>
                    </motion.div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AboutSection;
