import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-scroll';

const useStyles = makeStyles((theme) => ({
    scrollToTop: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: '50%',
        padding: '10px',
        boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'transform 0.3s ease, opacity 0.3s ease',
        opacity: 0,
        transform: 'translateY(100px)',
        [theme.breakpoints.down('xs')]: {
            bottom: '15px',
            right: '15px',
            padding: '8px',
        },
        [theme.breakpoints.down('sm')]: {
            bottom: '18px',
            right: '18px',
            padding: '9px',
        },
    },
    visible: {
        opacity: 1,
        transform: 'translateY(0)',
    },
}));

const ScrollToTop = () => {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <Link to="home" smooth={true} duration={1000} className={`${classes.scrollToTop} ${visible ? classes.visible : ''}`} aria-label="Scroll to top">
            <FaArrowUp size={20} />
        </Link>
    );
};

export default ScrollToTop;
