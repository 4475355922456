import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import MainApp from './components/MainApp';
import Header from './components/Header';
import Dashboard from './components/Dashboard'; // Adjust the path if necessary

const App = () => {
  const [showSplash, setShowSplash] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  // Only show Header on routes other than /dashboard
  const shouldShowHeader = location.pathname !== '/dashboard';

  return (
    <div>
      {!showSplash && shouldShowHeader && <Header />}
      <Routes>
        <Route path="/" element={<MainApp showSplash={showSplash} />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </div>
  );
};

const RootApp = () => (
  <Router>
    <App />
  </Router>
);

export default RootApp;
