// src/services/photoService.js
import axios from "../api/axios"

const fetchPhotos = async (query = 'marketing', perPage = '10') => {
    try {
        const response = await axios.post('/api/v1/pexels/search-photos', {
            query: query,
            per_page: perPage,
        });

        return response.data.photos; // Assuming response.data contains photos from server
    } catch (error) {
        console.error('Error fetching photos:', error);
        throw error; // Propagate error to caller
    }
};

export default fetchPhotos;
