// src/api/pexelsApi.js
import axios from 'axios';

const instance = axios.create({
  baseURL: 'http://localhost:4000', // Adjust URL according to your server setup
  timeout: 5000, // Timeout for requests
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
