// import React from 'react';
// import { BarChart, Bar, PieChart, Pie, LineChart, Line, Tooltip, Legend, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';
// import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';

// const dummyData = {
//     summary: {
//         totalReviews: 250,
//         averageRating: 4.2,
//         positiveSentiment: 60,
//         neutralSentiment: 25,
//         negativeSentiment: 15
//     },
//     reviewBySource: [
//         { name: 'Google', value: 100 },
//         { name: 'Instagram', value: 80 },
//         { name: 'Website', value: 70 }
//     ],
//     reviewTrends: [
//         { name: 'Jan', reviews: 20 },
//         { name: 'Feb', reviews: 30 },
//         { name: 'Mar', reviews: 25 },
//         { name: 'Apr', reviews: 40 },
//         { name: 'May', reviews: 35 },
//         { name: 'Jun', reviews: 50 }
//     ],
//     detailedReviews: [
//         { author: 'John Doe', text: 'Great service!', rating: 5 },
//         { author: 'Jane Smith', text: 'Average experience.', rating: 3 },
//         { author: 'Sam Wilson', text: 'Poor quality.', rating: 2 }
//     ]
// };

// const Dashboard = () => {
//     return (
//         <div style={{ padding: '20px' }}>
//             <Grid container spacing={3}>
//                 {/* Summary Statistics */}
//                 <Grid item xs={12} md={4}>
//                     <Card>
//                         <CardHeader title="Total Reviews" />
//                         <CardContent>
//                             <Typography variant="h5">{dummyData.summary.totalReviews}</Typography>
//                         </CardContent>
//                     </Card>
//                 </Grid>
//                 <Grid item xs={12} md={4}>
//                     <Card>
//                         <CardHeader title="Average Rating" />
//                         <CardContent>
//                             <Typography variant="h5">{dummyData.summary.averageRating}</Typography>
//                         </CardContent>
//                     </Card>
//                 </Grid>
//                 <Grid item xs={12} md={4}>
//                     <Card>
//                         <CardHeader title="Positive Sentiment" />
//                         <CardContent>
//                             <Typography variant="h5">{dummyData.summary.positiveSentiment}%</Typography>
//                         </CardContent>
//                     </Card>
//                 </Grid>

//                 {/* Pie Chart for Sentiment Distribution */}
//                 <Grid item xs={12}>
//                     <Card>
//                         <CardHeader title="Sentiment Distribution" />
//                         <CardContent>
//                             <ResponsiveContainer width="100%" height={300}>
//                                 <PieChart>
//                                     <Pie dataKey="value" data={[
//                                         { name: 'Positive', value: dummyData.summary.positiveSentiment },
//                                         { name: 'Neutral', value: dummyData.summary.neutralSentiment },
//                                         { name: 'Negative', value: dummyData.summary.negativeSentiment }
//                                     ]} fill="#8884d8" label />
//                                     <Tooltip />
//                                     <Legend />
//                                 </PieChart>
//                             </ResponsiveContainer>
//                         </CardContent>
//                     </Card>
//                 </Grid>

//                 {/* Bar Chart for Review Count by Source */}
//                 <Grid item xs={12}>
//                     <Card>
//                         <CardHeader title="Review Count by Source" />
//                         <CardContent>
//                             <ResponsiveContainer width="100%" height={300}>
//                                 <BarChart data={dummyData.reviewBySource}>
//                                     <CartesianGrid strokeDasharray="3 3" />
//                                     <XAxis dataKey="name" />
//                                     <YAxis />
//                                     <Tooltip />
//                                     <Legend />
//                                     <Bar dataKey="value" fill="#82ca9d" />
//                                 </BarChart>
//                             </ResponsiveContainer>
//                         </CardContent>
//                     </Card>
//                 </Grid>

//                 {/* Line Chart for Review Trends Over Time */}
//                 <Grid item xs={12}>
//                     <Card>
//                         <CardHeader title="Review Trends Over Time" />
//                         <CardContent>
//                             <ResponsiveContainer width="100%" height={300}>
//                                 <LineChart data={dummyData.reviewTrends}>
//                                     <CartesianGrid strokeDasharray="3 3" />
//                                     <XAxis dataKey="name" />
//                                     <YAxis />
//                                     <Tooltip />
//                                     <Legend />
//                                     <Line type="monotone" dataKey="reviews" stroke="#8884d8" />
//                                 </LineChart>
//                             </ResponsiveContainer>
//                         </CardContent>
//                     </Card>
//                 </Grid>

//                 {/* Detailed Reviews */}
//                 <Grid item xs={12}>
//                     <Card>
//                         <CardHeader title="Detailed Reviews" />
//                         <CardContent>
//                             <ul style={{ listStyleType: 'none', padding: '0' }}>
//                                 {dummyData.detailedReviews.map((review, index) => (
//                                     <li key={index} style={{ border: '1px solid #ddd', borderRadius: '4px', marginBottom: '10px', padding: '10px' }}>
//                                         <Typography variant="h6">{review.author}</Typography>
//                                         <Typography variant="body1">{review.text}</Typography>
//                                         <Typography variant="body2"><strong>Rating:</strong> {review.rating}</Typography>
//                                     </li>
//                                 ))}
//                             </ul>
//                         </CardContent>
//                     </Card>
//                 </Grid>
//             </Grid>
//         </div>
//     );
// };

// export default Dashboard;


//=====================================

// import React from 'react';
// import {
//     BarChart,
//     Bar,
//     PieChart,
//     Pie,
//     LineChart,
//     Line,
//     Tooltip,
//     Legend,
//     CartesianGrid,
//     XAxis,
//     YAxis,
//     ResponsiveContainer
// } from 'recharts';
// import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
// import { styled } from '@mui/system';

// // Use styled API without theme.spacing
// const Root = styled('div')(({ theme }) => ({
//     padding: '32px', // Directly use spacing values
//     backgroundColor: '#f0f2f5', // Lighter background for better contrast
// }));

// const StyledCard = styled(Card)(({ theme }) => ({
//     backgroundColor: '#ffffff',
//     boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)', // Softer shadow for a modern look
//     borderRadius: '12px',
//     overflow: 'hidden', // Ensure no content overflows card borders
// }));

// const CardHeaderStyled = styled(CardHeader)(({ theme }) => ({
//     backgroundColor: '#4a90e2', // Updated color for a more vibrant look
//     color: '#ffffff',
//     fontWeight: 'bold',
//     padding: '16px', // Directly use spacing values
//     fontSize: '1.2rem',
// }));

// const CardContentStyled = styled(CardContent)(({ theme }) => ({
//     padding: '24px', // Directly use spacing values
// }));

// const PieChartStyled = styled(PieChart)({
//     fill: '#4a90e2', // Use a vibrant color for the Pie chart
// });

// const BarChartStyled = styled(BarChart)({
//     fill: '#50e3c2', // Updated color for the Bar chart
// });

// const LineChartStyled = styled(LineChart)({
//     stroke: '#4a90e2', // Consistent color for the Line chart
// });

// const ReviewList = styled('ul')({
//     listStyleType: 'none',
//     padding: 0,
//     margin: 0,
// });

// const ReviewItem = styled('li')(({ theme }) => ({
//     border: '1px solid #ddd',
//     borderRadius: '8px',
//     marginBottom: '16px', // Directly use spacing values
//     padding: '16px', // Directly use spacing values
//     backgroundColor: '#ffffff',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
// }));

// const InsightList = styled('ul')({
//     listStyleType: 'none',
//     padding: 0,
//     margin: 0,
// });

// const InsightItem = styled('li')(({ theme }) => ({
//     marginBottom: '8px', // Directly use spacing values
// }));

// const dummyData = {
//     summary: {
//         totalReviews: 250,
//         averageRating: 4.2,
//         positiveSentiment: 60,
//         neutralSentiment: 25,
//         negativeSentiment: 15,
//     },
//     reviewBySource: [
//         { name: 'Google', value: 100 },
//         { name: 'Instagram', value: 80 },
//         { name: 'Website', value: 70 },
//     ],
//     reviewTrends: [
//         { name: 'Jan', reviews: 20 },
//         { name: 'Feb', reviews: 30 },
//         { name: 'Mar', reviews: 25 },
//         { name: 'Apr', reviews: 40 },
//         { name: 'May', reviews: 35 },
//         { name: 'Jun', reviews: 50 },
//     ],
//     detailedReviews: [
//         { author: 'John Doe', text: 'Great service!', rating: 5 },
//         { author: 'Jane Smith', text: 'Average experience.', rating: 3 },
//         { author: 'Sam Wilson', text: 'Poor quality.', rating: 2 },
//     ],
//     actionableInsights: [
//         'Increase engagement on Instagram by posting daily stories.',
//         'Respond to all Google reviews to enhance customer relations.',
//         'Improve website SEO to attract more organic traffic.',
//         'Offer promotions or discounts based on review feedback.',
//         'Enhance customer service training to address negative feedback.',
//     ],
// };

// const Dashboard = () => {
//     return (
//         <Root>
//             <Grid container spacing={4}>
//                 {/* Summary Statistics */}
//                 <Grid item xs={12} sm={6} md={4}>
//                     <StyledCard>
//                         <CardHeaderStyled title="Total Reviews" />
//                         <CardContentStyled>
//                             <Typography variant="h4" color="textPrimary">{dummyData.summary.totalReviews}</Typography>
//                         </CardContentStyled>
//                     </StyledCard>
//                 </Grid>
//                 <Grid item xs={12} sm={6} md={4}>
//                     <StyledCard>
//                         <CardHeaderStyled title="Average Rating" />
//                         <CardContentStyled>
//                             <Typography variant="h4" color="textPrimary">{dummyData.summary.averageRating}</Typography>
//                         </CardContentStyled>
//                     </StyledCard>
//                 </Grid>
//                 <Grid item xs={12} sm={6} md={4}>
//                     <StyledCard>
//                         <CardHeaderStyled title="Positive Sentiment" />
//                         <CardContentStyled>
//                             <Typography variant="h4" color="textPrimary">{dummyData.summary.positiveSentiment}%</Typography>
//                         </CardContentStyled>
//                     </StyledCard>
//                 </Grid>

//                 {/* Pie Chart for Sentiment Distribution */}
//                 <Grid item xs={12}>
//                     <StyledCard>
//                         <CardHeaderStyled title="Sentiment Distribution" />
//                         <CardContentStyled>
//                             <ResponsiveContainer width="100%" height={300}>
//                                 <PieChartStyled>
//                                     <Pie
//                                         dataKey="value"
//                                         data={[
//                                             { name: 'Positive', value: dummyData.summary.positiveSentiment },
//                                             { name: 'Neutral', value: dummyData.summary.neutralSentiment },
//                                             { name: 'Negative', value: dummyData.summary.negativeSentiment },
//                                         ]}
//                                         fill="#4a90e2"
//                                         label
//                                     />
//                                     <Tooltip />
//                                     <Legend />
//                                 </PieChartStyled>
//                             </ResponsiveContainer>
//                         </CardContentStyled>
//                     </StyledCard>
//                 </Grid>

//                 {/* Bar Chart for Review Count by Source */}
//                 <Grid item xs={12}>
//                     <StyledCard>
//                         <CardHeaderStyled title="Review Count by Source" />
//                         <CardContentStyled>
//                             <ResponsiveContainer width="100%" height={300}>
//                                 <BarChartStyled data={dummyData.reviewBySource}>
//                                     <CartesianGrid strokeDasharray="3 3" />
//                                     <XAxis dataKey="name" />
//                                     <YAxis />
//                                     <Tooltip />
//                                     <Legend />
//                                     <Bar dataKey="value" fill="#50e3c2" />
//                                 </BarChartStyled>
//                             </ResponsiveContainer>
//                         </CardContentStyled>
//                     </StyledCard>
//                 </Grid>

//                 {/* Line Chart for Review Trends Over Time */}
//                 <Grid item xs={12}>
//                     <StyledCard>
//                         <CardHeaderStyled title="Review Trends Over Time" />
//                         <CardContentStyled>
//                             <ResponsiveContainer width="100%" height={300}>
//                                 <LineChartStyled data={dummyData.reviewTrends}>
//                                     <CartesianGrid strokeDasharray="3 3" />
//                                     <XAxis dataKey="name" />
//                                     <YAxis />
//                                     <Tooltip />
//                                     <Legend />
//                                     <Line type="monotone" dataKey="reviews" stroke="#4a90e2" />
//                                 </LineChartStyled>
//                             </ResponsiveContainer>
//                         </CardContentStyled>
//                     </StyledCard>
//                 </Grid>

//                 {/* Detailed Reviews */}
//                 <Grid item xs={12}>
//                     <StyledCard>
//                         <CardHeaderStyled title="Detailed Reviews" />
//                         <CardContentStyled>
//                             <ReviewList>
//                                 {dummyData.detailedReviews.map((review, index) => (
//                                     <ReviewItem key={index}>
//                                         <Typography variant="h6" color="textPrimary">{review.author}</Typography>
//                                         <Typography variant="body1" color="textSecondary">{review.text}</Typography>
//                                         <Typography variant="body2" color="textSecondary">
//                                             <strong>Rating:</strong> {review.rating}
//                                         </Typography>
//                                     </ReviewItem>
//                                 ))}
//                             </ReviewList>
//                         </CardContentStyled>
//                     </StyledCard>
//                 </Grid>

//                 {/* Actionable Insights */}
//                 <Grid item xs={12}>
//                     <StyledCard>
//                         <CardHeaderStyled title="Actionable Insights" />
//                         <CardContentStyled>
//                             <InsightList>
//                                 {dummyData.actionableInsights.map((insight, index) => (
//                                     <InsightItem key={index}>
//                                         <Typography variant="body1" color="textSecondary">- {insight}</Typography>
//                                     </InsightItem>
//                                 ))}
//                             </InsightList>
//                         </CardContentStyled>
//                     </StyledCard>
//                 </Grid>
//             </Grid>
//         </Root>
//     );
// };

// export default Dashboard;



//======================

import React from 'react';
import {
    BarChart,
    Bar,
    PieChart,
    Pie,
    LineChart,
    Line,
    Tooltip,
    Legend,
    CartesianGrid,
    XAxis,
    YAxis,
    ResponsiveContainer
} from 'recharts';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Define a gradient color for the background
const gradientBackground = 'linear-gradient(to right, #f50057, #000000)';

const Root = styled('div')({
    padding: '32px',
    background: '#f0f2f5', // Light background for contrast
});

const StyledCard = styled(Card)({
    backgroundColor: '#ffffff',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)', // Softer shadow for a modern look
    borderRadius: '12px',
    overflow: 'hidden',
});

const CardHeaderStyled = styled(CardHeader)({
    background: gradientBackground, // Gradient background for header
    color: '#ffffff',
    fontWeight: 'bold',
    padding: '16px',
    fontSize: '1.2rem',
});

const CardContentStyled = styled(CardContent)({
    padding: '24px',
});

const PieChartStyled = styled(PieChart)({
    fill: '#f50057', // Use a vibrant pink for the Pie chart
});

const BarChartStyled = styled(BarChart)({
    fill: '#f50057', // Use pink for the Bar chart
});

const LineChartStyled = styled(LineChart)({
    stroke: '#f50057', // Pink stroke for the Line chart
});

const ReviewList = styled('ul')({
    listStyleType: 'none',
    padding: 0,
    margin: 0,
});

const ReviewItem = styled('li')({
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginBottom: '16px',
    padding: '16px',
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});

const InsightList = styled('ul')({
    listStyleType: 'none',
    padding: 0,
    margin: 0,
});

const InsightItem = styled('li')({
    marginBottom: '8px',
});

const dummyData = {
    summary: {
        totalReviews: 250,
        averageRating: 4.2,
        positiveSentiment: 60,
        neutralSentiment: 25,
        negativeSentiment: 15,
    },
    reviewBySource: [
        { name: 'Google', value: 100 },
        { name: 'Instagram', value: 80 },
        { name: 'Website', value: 70 },
    ],
    reviewTrends: [
        { name: 'Jan', reviews: 20 },
        { name: 'Feb', reviews: 30 },
        { name: 'Mar', reviews: 25 },
        { name: 'Apr', reviews: 40 },
        { name: 'May', reviews: 35 },
        { name: 'Jun', reviews: 50 },
    ],
    detailedReviews: [
        { author: 'John Doe', text: 'Great service!', rating: 5 },
        { author: 'Jane Smith', text: 'Average experience.', rating: 3 },
        { author: 'Sam Wilson', text: 'Poor quality.', rating: 2 },
    ],
    actionableInsights: [
        'Increase engagement on Instagram by posting daily stories.',
        'Respond to all Google reviews to enhance customer relations.',
        'Improve website SEO to attract more organic traffic.',
        'Offer promotions or discounts based on review feedback.',
        'Enhance customer service training to address negative feedback.',
    ],
};

const Dashboard = () => {
    return (
        <Root>
            <Grid container spacing={4}>
                {/* Summary Statistics */}
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardHeaderStyled title="Total Reviews" />
                        <CardContentStyled>
                            <Typography variant="h4" color="textPrimary">{dummyData.summary.totalReviews}</Typography>
                        </CardContentStyled>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardHeaderStyled title="Average Rating" />
                        <CardContentStyled>
                            <Typography variant="h4" color="textPrimary">{dummyData.summary.averageRating}</Typography>
                        </CardContentStyled>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardHeaderStyled title="Positive Sentiment" />
                        <CardContentStyled>
                            <Typography variant="h4" color="textPrimary">{dummyData.summary.positiveSentiment}%</Typography>
                        </CardContentStyled>
                    </StyledCard>
                </Grid>

                {/* Pie Chart for Sentiment Distribution */}
                <Grid item xs={12}>
                    <StyledCard>
                        <CardHeaderStyled title="Sentiment Distribution" />
                        <CardContentStyled>
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChartStyled>
                                    <Pie
                                        dataKey="value"
                                        data={[
                                            { name: 'Positive', value: dummyData.summary.positiveSentiment },
                                            { name: 'Neutral', value: dummyData.summary.neutralSentiment },
                                            { name: 'Negative', value: dummyData.summary.negativeSentiment },
                                        ]}
                                        fill="#f50057"
                                        label
                                    />
                                    <Tooltip />
                                    <Legend />
                                </PieChartStyled>
                            </ResponsiveContainer>
                        </CardContentStyled>
                    </StyledCard>
                </Grid>

                {/* Bar Chart for Review Count by Source */}
                <Grid item xs={12}>
                    <StyledCard>
                        <CardHeaderStyled title="Review Count by Source" />
                        <CardContentStyled>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChartStyled data={dummyData.reviewBySource}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="value" fill="#f50057" />
                                </BarChartStyled>
                            </ResponsiveContainer>
                        </CardContentStyled>
                    </StyledCard>
                </Grid>

                {/* Line Chart for Review Trends Over Time */}
                <Grid item xs={12}>
                    <StyledCard>
                        <CardHeaderStyled title="Review Trends Over Time" />
                        <CardContentStyled>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChartStyled data={dummyData.reviewTrends}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="reviews" stroke="#f50057" />
                                </LineChartStyled>
                            </ResponsiveContainer>
                        </CardContentStyled>
                    </StyledCard>
                </Grid>

                {/* Detailed Reviews */}
                <Grid item xs={12}>
                    <StyledCard>
                        <CardHeaderStyled title="Detailed Reviews" />
                        <CardContentStyled>
                            <ReviewList>
                                {dummyData.detailedReviews.map((review, index) => (
                                    <ReviewItem key={index}>
                                        <Typography variant="h6" color="textPrimary">{review.author}</Typography>
                                        <Typography variant="body1" color="textSecondary">{review.text}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            <strong>Rating:</strong> {review.rating}
                                        </Typography>
                                    </ReviewItem>
                                ))}
                            </ReviewList>
                        </CardContentStyled>
                    </StyledCard>
                </Grid>

                {/* Actionable Insights */}
                <Grid item xs={12}>
                    <StyledCard>
                        <CardHeaderStyled title="Actionable Insights" />
                        <CardContentStyled>
                            <InsightList>
                                {dummyData.actionableInsights.map((insight, index) => (
                                    <InsightItem key={index}>
                                        <Typography variant="body1" color="textSecondary">- {insight}</Typography>
                                    </InsightItem>
                                ))}
                            </InsightList>
                        </CardContentStyled>
                    </StyledCard>
                </Grid>
            </Grid>
        </Root>
    );
};

export default Dashboard;
