import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles';
import logoImage from '../assets/images/DigiAuraSpalshScreen.jpg';
import ParticlePages from './ParticlePages';

const useStyles = makeStyles((theme) => ({
    splashScreen: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent', // Changed from '#000' to 'transparent'
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300, // Ensure it covers everything
        textAlign: 'center',
    },
    splashImage: {
        width: '80%', // Use percentage for responsive width
        maxWidth: 400, // Max width for larger screens
        height: 'auto', // Maintain aspect ratio
        [theme.breakpoints.up('sm')]: {
            maxWidth: 500, // Max width for medium screens
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 300, // Adjusted max width for larger screens
        },
    },
    tagline: {
        marginTop: theme.spacing(2), // Adjust spacing as needed
        color: '#fff', // White color for tagline text
        fontFamily: 'Arial, sans-serif',
        fontSize: '1rem', // Base font size
        fontWeight: 'bold', // Optional: Adjust font weight as needed
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem', // Font size for medium screens
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem', // Font size for larger screens
        },
    },
}));

const SplashScreen = () => {
    const classes = useStyles();

    return (
        <>
            <Helmet>
                <title>Your Splash Screen Title | DigiAura</title>
                <meta name="description" content="Brief description of your splash screen content." />
                <meta name="keywords" content="keywords, relevant, to, your, content" />
            </Helmet>
            <ParticlePages />
            <div className={classes.splashScreen}>
                <motion.img
                    src={logoImage}
                    alt="DigiAura Splash Screen Logo"
                    className={classes.splashImage}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 5 }}
                />
                <motion.div
                    className={classes.tagline}
                    initial={{ opacity: 0, scale: 0.8, y: 20 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    transition={{ delay: 0.5, duration: 5, ease: 'easeOut' }}
                >
                    We treat your brand as our own.
                </motion.div>
            </div>
        </>
    );
};

export default SplashScreen;
