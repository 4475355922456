import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import HeroSection from './HeroSection';
import ServicesSection from './ServicesSection';
import ClientsSection from './ClientsSection';
import AboutSection from './AboutSection';
import TestimonialsSection from './TestimonialsSection';
import ContactSection from './ContactSection';
import Footer from './Footer';
import SplashScreen from './SplashScreen';
import ScrollToTop from './ScrollToTop';
import './../index.css';

const MainApp = ({ showSplash }) => {
    const servicesRef = useRef(null);

    if (showSplash) {
        return <SplashScreen />;
    }

    return (
        <>
            <Helmet>
                <title>DigiAura Marketing Agency</title>
                <meta name="description" content="Welcome to DigiAura Marketing Agency. We specialize in digital marketing solutions tailored to your needs." />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://www.digiauramarketingsolutions.com/" />
            </Helmet>
            <Header />
            <div id="home">
                <HeroSection />
            </div>
            <div id="services" ref={servicesRef}>
                <ServicesSection />
            </div>
            <div id="about us">
                <AboutSection />
            </div>
            <div id="clients">
                <ClientsSection />
            </div>
            <div id="contact">
                <ContactSection />
            </div>
            <TestimonialsSection />
            <Footer />
            <ScrollToTop />
        </>
    );
};

export default MainApp;
