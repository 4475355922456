import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Hidden, Tooltip, Menu, MenuItem, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-scroll';
import logoImage from '../assets/images/DigiAuraHeader.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#000000',
        transition: 'background-color 0.3s ease',
        padding: theme.spacing(1, 2),
        position: 'fixed',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 700,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'top',
    },
    logo: {
        marginTop: '6px',
        marginRight: theme.spacing(1),
        width: 33,
        height: 30,
    },
    button: {
        marginLeft: theme.spacing(2),
        color: '#fff',
        position: 'relative',
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: '#555',
            borderRadius: '12px',
            '&:after': {
                background: 'linear-gradient(to right, #FFFF00, #00FF00)',
                transform: 'scaleX(1)',
            },
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '85%',
            height: '2px',
            bottom: 0,
            left: 6,
            backgroundColor: '#ccc',
            transform: 'scaleX(0)',
            transition: 'transform 0.3s ease, background-color 0.3s ease',
        },
    },
    getInTouchButton: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1, 2),
        backgroundColor: '#f50057',
        borderRadius: '8px',
        color: '#fff',
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: '#f50057',
            filter: 'brightness(85%)',
        },
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
    },
    navLinks: {
        display: 'flex',
        alignItems: 'center',
    },
    menuPaper: {
        backgroundColor: '#333',
        color: '#fff',
    },
    menuItem: {
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#555',
        },
    },
    fadeBottom: {
        background: 'linear-gradient(180deg, transparent, rgba(37, 37, 37, 0.61), #111)',
    },
    headerTitle: {
        fontSize: '2.5rem',
    },
    dashboardButton: {
        // marginLeft: theme.spacing(2),
        // color: '#fff',
        // backgroundColor: '#007BFF',
        // '&:hover': {
        //   backgroundColor: '#0056b3',
        // },
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1, 2),
        backgroundColor: '#f50057',
        borderRadius: '8px',
        color: '#fff',
        textTransform: 'none',
        fontSize: '1rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f50057',
            filter: 'brightness(85%)',  // This will create a faded effect
        },
    },
}));

const Header = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const scrollOffset = -70; // Adjust this value to set how much higher the section should be

    const handleDashboardClick = () => {
        window.open('/dashboard', '_blank');
    };

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <div className={classes.fadeBottom} />
                <Toolbar className={classes.toolbar}>
                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className={classes.title}>
                            <img src={logoImage} alt="Logo" className={classes.logo} />
                            <Typography variant="h1" className={`${classes.headerTitle}`}>
                                DigiAura
                            </Typography>
                        </div>
                    </motion.div>
                    <Hidden smDown>
                        <div className={classes.navLinks}>
                            {['Home', 'Services', 'About us', 'Clients'].map((text, index) => (
                                <motion.div
                                    key={text}
                                    initial={{ opacity: 0, y: -20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 * (index + 1), duration: 0.5 }}
                                    whileHover={{ scale: 1.1 }}
                                >
                                    <Link to={text.toLowerCase()} smooth={true} duration={1000} offset={scrollOffset}>
                                        <Button color="inherit" className={classes.button}>
                                            {text}
                                        </Button>
                                    </Link>
                                </motion.div>
                            ))}
                            <motion.div
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.7, duration: 0.5 }}
                                whileHover={{ scale: 1.1 }}
                            >
                                <Link to="contact" smooth={true} duration={1000} offset={scrollOffset}>
                                    <Button
                                        variant="contained"
                                        className={classes.getInTouchButton}
                                    >
                                        Get in Touch
                                    </Button>
                                </Link>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.9, duration: 0.5 }}
                                whileHover={{ scale: 1.1 }}
                            >
                                {/* <Typography
                                    className={classes.dashboardButton}
                                    onClick={handleDashboardClick}
                                >
                                    Dashboard
                                </Typography> */}
                            </motion.div>
                        </div>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={handleMenuOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            classes={{ paper: classes.menuPaper }}
                        >
                            {['Home', 'Services', 'About us', 'Clients', 'Contact'].map((text, index) => (
                                <MenuItem
                                    key={text}
                                    onClick={handleMenuClose}
                                    className={classes.menuItem}
                                >
                                    <Link to={text.toLowerCase()} smooth={true} duration={1000} offset={scrollOffset}>
                                        <Button color="inherit" className={classes.button}>
                                            {text}
                                        </Button>
                                    </Link>
                                </MenuItem>
                            ))}
                            <MenuItem onClick={handleDashboardClick} className={classes.menuItem} >
                                {/* <Typography style={{ marginLeft: "25px", marginTop: "10px" }}>
                                    Dashboard
                                </Typography> */}
                            </MenuItem>
                        </Menu>
                    </Hidden>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
