import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import CloseIcon from '@material-ui/icons/Close';
import { useInView } from 'react-intersection-observer';

const useStyles = makeStyles((theme) => ({
    contactSection: {
        padding: theme.spacing(4, 0), // Reduced padding for smaller devices
        background: 'linear-gradient(135deg, #00c6ff, #ffcf00)',
        color: '#000000',
       
    },
    container: {
        maxWidth: 1200,
        margin: '0 auto',
        padding: theme.spacing(0, 2),
         marginBottom: "40px"
    },
    sectionTitle: {
        textAlign: 'center',
        marginBottom: theme.spacing(2), // Adjusted margin for smaller screens
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(4),
        },
    },
    sectionText: {
        textAlign: 'center',
        marginBottom: theme.spacing(2), // Adjusted margin for smaller screens
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(4),
        },
    },
    contactForm: {
        backgroundColor: 'transparent', // Set form background to transparent
        boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.2)',
        padding: theme.spacing(2),
        borderRadius: 8,
        border: '2px solid #000000',
        width: '100%',
        boxSizing: 'border-box', // Ensure padding is included in the element's width
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4),
        },
    },
    contactButton: {
        backgroundColor: '#329ba8',
        '&:hover': {
            backgroundColor: '#329ba8',
        },
        width: '100%', // Full width on smaller screens
        [theme.breakpoints.up('sm')]: {
            width: 'auto', // Auto width on larger screens
        },
    },
    formStatus: {
        marginTop: theme.spacing(2),
    },
    snackbarSuccess: {
        backgroundColor: theme.palette.success.main,
    },
    snackbarError: {
        backgroundColor: theme.palette.error.main,
    },
    snackbarMessage: {
        display: 'flex',
        alignItems: 'center',
    },
    closeIcon: {
        fontSize: 20,
        marginLeft: theme.spacing(1),
    },
}));

const ContactSection = () => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        from_name: '',
        from_email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.3,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.from_name || !formData.from_email || !formData.message) {
            handleSnackbarOpen('error', 'Please fill in all required fields.');
            return;
        }
        emailjs.sendForm('service_u3de87j', 'template_n454h7r', e.target, 'AZyrrUHPPeAjSsoaY')
            .then((result) => {
                handleSnackbarOpen('success', 'Thank you for sending your request. We will contact you shortly.');
                resetForm();
            }, (error) => {
                handleSnackbarOpen('error', 'We will contact you with you shortly.');
            });
    };

    const handleSnackbarOpen = (type, message) => {
        setSnackbarType(type);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000); // Hide Snackbar after 3 seconds
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const resetForm = () => {
        setFormData({
            from_name: '',
            from_email: '',
            subject: '',
            phone: '',
            message: ''
        });
    };

    return (
        <motion.section
            className={classes.contactSection}
            id="contact"
            aria-label="contact"
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : {}}
            transition={{ duration: 0.8 }}
        >
            <div className={classes.container} ref={ref}>
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.8 }}
                >
                    <Typography variant="h3" className={classes.sectionTitle}>
                        <ContactSupportIcon />  Get In Touch
                    </Typography>
                    <Typography variant="body1" className={classes.sectionText}>
                        At DigiAura, we believe in meaningful connections. Whether you have a question about our services, want to explore partnership opportunities, or simply want to say hello, we're here and ready to listen. Drop us a message and let's start a conversation that leads to great things.
                    </Typography>
                </motion.div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <motion.div
                            className={classes.contactForm}
                            initial={{ opacity: 0, x: -50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.8 }}
                        >
                            <Typography variant="h4" gutterBottom>
                                Request a call back
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Name"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="from_name"
                                            value={formData.from_name}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Email"
                                            type="email"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="from_email"
                                            value={formData.from_email}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Subject"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Phone"
                                            type="tel"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Message"
                                            variant="outlined"
                                            margin="normal"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.contactButton}
                                        >
                                            Send Message
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </motion.div>
                    </Grid>
                </Grid>
            </div>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={3000} // Set auto hide duration to 3 seconds
                onClose={handleSnackbarClose}
            >
                <SnackbarContent
                    className={snackbarType === 'success' ? classes.snackbarSuccess : classes.snackbarError}
                    message={
                        <span className={classes.snackbarMessage}>
                            {snackbarMessage}
                        </span>
                    }
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    }
                />
            </Snackbar>

        </motion.section>
    );
};

export default ContactSection;
