import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import EmaraatCreators from '../assets/images/EmaraatCreators.png';
import MoryaPark from '../assets/images/MoryaPark.jpg';
import RamdegiDevelopers from '../assets/images/RamdegiDevelopers.jpg';
import SonalProperties from '../assets/images/SonalProperties.jpg';
import LazyLoad from 'react-lazyload';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Diversity3Icon from '@mui/icons-material/Diversity3';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(8), // Adjust top padding for mobile devices
        paddingBottom: theme.spacing(8), // Adjust bottom padding for mobile devices
        backgroundColor: 'black',
        color: 'white',
        textAlign: 'center', // Center align content
    },
    container: {
        marginTop: theme.spacing(4), // Add top margin for spacing
        marginBottom: theme.spacing(4), // Add bottom margin for spacing
        padding: theme.spacing(2), // Padding around the container
    },
    heading: {
        fontWeight: 700,
        marginBottom: theme.spacing(2),
        color: 'white',
        textAlign: 'center',
    },
    logo: {
        width: '100%',
        height: 'auto', // Ensure logos scale proportionally
        maxWidth: '100px', // Limit maximum width for better mobile layout
        padding: theme.spacing(1), // Padding around logos
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for logos
    },
}));

const clients = [
    { id: 1, icon: EmaraatCreators },
    { id: 2, icon: MoryaPark },
    { id: 3, icon: RamdegiDevelopers },
    { id: 4, icon: SonalProperties },
];

const ClientsSection = () => {
    const classes = useStyles();

    // Use useInView hook to detect when each image comes into view
    const { ref, inView } = useInView({
        triggerOnce: false, // Trigger animation every time component enters view
        threshold: 0.3, // Adjust threshold as needed
    });

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.heading}>
                <Diversity3Icon /> Our Clients
            </Typography>
            <div className={classes.container}>
                <Grid container spacing={2} justify="center">
                    {clients.map((client) => (
                        <Grid item key={client.id} ref={ref}>
                            <LazyLoad height={100} offset={100}>
                                <motion.img
                                    src={client.icon}
                                    alt={`Client ${client.id}`}
                                    className={classes.logo}
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={inView ? { opacity: 1, y: 0 } : {}}
                                    transition={{ duration: 1 }}
                                />
                            </LazyLoad>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default ClientsSection;
