import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';
import fetchPhotos from '../client-api/services/pexels'; // Adjust import as needed
import heroDefaultImage from '../assets/images/heroDefaultImage.png'; // Import default image

const useStyles = makeStyles((theme) => ({
    heroSection: {
        marginTop: theme.spacing(10),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 64px)',
        backgroundColor: '#000',
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            padding: theme.spacing(4),
        },
    },
    heroContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '1200px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    textContent: {
        textAlign: 'left',
        maxWidth: '600px',
        paddingRight: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            paddingRight: 0,
            marginBottom: theme.spacing(4),
        },
    },
    imageContainer: {
        maxWidth: '700px',
        width: '100%',
        height: '450px',
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            maxHeight: '500px',
        },
    },
    heroButton: {
        marginTop: theme.spacing(4),
    },
    heading: {
        color: '#fff',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
    },
    subheading: {
        color: '#bbb',
    },
    verticalLine: {
        height: '100%',
        borderLeft: `3px solid ${theme.palette.secondary.main}`,
        marginLeft: theme.spacing(0.5),
    }, headerTitle: {
        fontSize: '4rem',
    },
}));

const HeroSection = ({ servicesRef }) => {
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showDefaultImage, setShowDefaultImage] = useState(true); // State to control showing default image

    useEffect(() => {
        const fetchAndSetPhotos = async () => {
            try {
                const digitalMarketingPhotos = await fetchPhotos('Digital marketing', 100);
                const landscapeImages = digitalMarketingPhotos.map(photo => photo.src.large);
                setImages(landscapeImages);
            } catch (error) {
                console.error('Error fetching photos:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAndSetPhotos();

        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 10000);

        return () => clearInterval(interval);
    }, [images.length]);

    useEffect(() => {
        // Show default image until images are loaded from API
        if (!isLoading && images.length > 0) {
            setShowDefaultImage(false);
        }
    }, [isLoading, images.length]);

    return (
        <div className={classes.heroSection}>
            <div className={classes.heroContent}>
                <div className={classes.textContent}>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <Typography variant="h6" gutterBottom>
                            <span className={`${classes.verticalLine} ${classes.heading}`}>&nbsp;&nbsp;DigiAura Marketing Solutions</span>
                        </Typography>
                        <Typography variant="h1" className={`${classes.heading} ${classes.headerTitle}`} gutterBottom>
                            Elevate Your Brand with Us.
                        </Typography>
                        <Typography variant="h5" className={classes.subheading} gutterBottom>
                            We are your partner in driving growth through innovative and data-driven marketing strategies. We help businesses amplify their presence, engage their audience, and achieve measurable success.
                        </Typography>
                        <Typography variant="h5" className={classes.subheading} gutterBottom>
                            Unlock your business potential with us.
                        </Typography>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 0.8 }}
                    >
                        <ScrollLink
                            to="services"
                            smooth={true}
                            duration={800}
                            spy={true}
                            offset={-64}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                className={classes.heroButton}
                            >
                                Get Started
                            </Button>
                        </ScrollLink>
                    </motion.div>
                </div>
                <div className={classes.imageContainer}>
                    {showDefaultImage && (
                        <img
                            src={heroDefaultImage}
                            alt="Default Image"
                            style={{ width: '100%', height: '100%', objectFit: 'cover', opacity: 1 }}
                        />
                    )}
                    {!showDefaultImage && (
                        <motion.img
                            key={images[currentImageIndex]}
                            src={images[currentImageIndex]}
                            alt={`Nature Image ${currentImageIndex + 1}`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.8 }}
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
