import React from 'react';
import { Grid, Card, CardContent, Typography, Avatar, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import Carousel from 'react-material-ui-carousel';
import AssessmentIcon from '@mui/icons-material/Assessment';

const useStyles = makeStyles((theme) => ({
    testimonialsSection: {
        marginTop: "50px",
        padding: theme.spacing(4, 0), // Adjusted padding for smaller devices
    },
    testimonialCard: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: theme.spacing(4),
        backgroundColor: '#000000', // Black background color
        color: '#FFFFFF', // White text color
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    sectionHeading: {
        marginBottom: theme.spacing(2), // Reduced margin for smaller devices
        textAlign: 'center',
        color: '#FFFFFF', // White color
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
    },
    icon: {
        marginRight: theme.spacing(1), // Add space between icon and text
    },
}));

const TestimonialsSection = () => {
    const classes = useStyles();

    const testimonials = [
        {
            name: 'Dhawal Bhagat',
            quote: 'Working with this agency has been a game-changer for our business.',
            image: "",
        },
        {
            name: 'Sonal Bhagat',
            quote: 'The team is professional, creative, and delivers results.',
            image: "",
        }
    ];

    return (
        <div className={classes.testimonialsSection}>
            <Container>
                <Typography variant="h4" className={classes.sectionHeading}>
                    <AssessmentIcon className={classes.icon} /> Testimonials
                </Typography>
                <Carousel animation="slide" indicators={false} navButtonsAlwaysVisible>
                    {testimonials.map((testimonial, index) => (
                        <Grid container justify="center" key={index}>
                            <Grid item xs={12} sm={8} md={6} lg={4}>
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <Card className={classes.testimonialCard}>
                                        <Avatar
                                            className={classes.avatar}
                                            src={testimonial.image}
                                            alt={testimonial.name}
                                        />
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom>
                                                {testimonial.name}
                                            </Typography>
                                            <Typography variant="body2">{testimonial.quote}</Typography>
                                        </CardContent>
                                    </Card>
                                </motion.div>
                            </Grid>
                        </Grid>
                    ))}
                </Carousel>
            </Container>
        </div>
    );

};

export default TestimonialsSection;
