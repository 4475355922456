import React, { useRef, useEffect } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import ContentIcon from '../assets/images/contentCreation.jpeg';
import WebIcon from '../assets/images/webDesign.avif';
import BrandingIcon from '../assets/images/branding.avif';
import BulkMediaMarketing from '../assets/images/bulkWhatsAppMarketing.webp';
import DataAnalytics from '../assets/images/dataAnalytics.jpeg';
import EmailMarketing from '../assets/images/emailMarketing.jpeg';
import LeadGeneration from '../assets/images/leadGeneration.jpeg';
import SeoGeneration from '../assets/images/seoGeneration.jpeg';
import SocialMedia from '../assets/images/socialMedia.jpeg';
import ServicesIcon from '@material-ui/icons/Work';
import { useInView } from 'react-intersection-observer';

const useStyles = makeStyles((theme) => ({
    servicesSection: {
        padding: theme.spacing(8, 0),
    },
    serviceCard: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: theme.spacing(4),
        backgroundColor: '#000',
        color: '#fff',
        boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.2)',
        transition: 'transform 0.3s ease',
        border: `1px solid`,
        borderImage: `linear-gradient(135deg, #00c6ff, #ffcf00) 1`,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
    },
    serviceImageContainer: {
        flex: '0 0 75%',
        width: '100%',
        height: '200px', // Set a fixed height for the image container
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '150px',
        },
    },
    serviceImage: {
        width: '100%',
        height: '100%',
        objectFit: 'contain', // This will ensure the image fits within the container while maintaining its aspect ratio
    },
    serviceContent: {
        flex: '0 0 25%',
        width: '100%',
        padding: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1),
        },
    },
    serviceTitle: {
        marginBottom: theme.spacing(1),
    },
    serviceDescription: {
        fontSize: '0.9rem',
    },
    sectionTitle: {
        textAlign: 'center',
        marginBottom: theme.spacing(4),
        color: '#000',
        fontWeight: 'bold', // Make the text bold
    },
    cardContainer: {
        display: 'flex',
        overflow: 'hidden',
    },
    cardItem: {
        flex: '0 0 25%',
        maxWidth: '25%',
        padding: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
        [theme.breakpoints.down('sm')]: {
            flex: '0 0 100%',
            maxWidth: '100%',
        },
    },
}));

const ServicesSection = () => {
    const classes = useStyles();
    const containerRef = useRef(null);
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.5,
    });

    const services = [
        {
            image: WebIcon,
            title: 'Web Design',
            description: 'Creating stunning, user-friendly websites that are tailored to your business needs for exceptional user experience.',
        },
        {
            image: BrandingIcon, // Replace with actual image source
            title: 'Branding',
            description: 'Building strong, memorable brand identities that resonate with your audience and establish your presence.',
        },
        {
            image: SocialMedia,
            title: 'Social Media',
            description: 'Engaging audiences, driving results on social platforms with compelling content, campaigns, and community interactions.',
        },
        {
            image: ContentIcon,
            title: 'Content Creation',
            description: 'Producing high-quality, compelling content like blog posts, videos, and graphics to enhance your presence.',
        },
        {
            image: EmailMarketing, // Replace with actual image source
            title: 'Email Marketing',
            description: 'Crafting targeted email campaigns that engage subscribers, nurture leads, and drive conversions for your business.',
        },
        {
            image: SeoGeneration, // Replace with actual image source
            title: 'SEO Services',
            description: 'Optimizing your website to achieve higher rankings on search engines and attract more organic traffic efficiency.',
        },
        {
            image: DataAnalytics, // Replace with actual image source
            title: 'Analytics & Reporting',
            description: 'Providing detailed analytics and reports to measure performance and guide strategic business decisions accurately.',
        },
        {
            image: LeadGeneration, // Replace with actual image source
            title: 'Lead Generation',
            description: 'Generating high-quality leads through various strategies to grow your customer base and increase sales opportunities.',
        },
        {
            image: BulkMediaMarketing, // Replace with actual image source
            title: 'WhatsApp Marketing',
            description: 'Executing powerful WhatsApp campaigns to engage users, significantly amplify brand presence, and drive conversions.',
        },
        
        
    ];



    useEffect(() => {
        const container = containerRef.current;
        let interval;

        const startAnimation = () => {
            let currentScrollLeft = container.scrollLeft;
            interval = setInterval(() => {
                currentScrollLeft -= 1;
                container.scrollLeft = currentScrollLeft;
                if (currentScrollLeft <= 0) {
                    currentScrollLeft = container.scrollWidth - container.offsetWidth;
                }
            }, 16);
        };

        const stopAnimation = () => {
            clearInterval(interval);
        };

        container.addEventListener('mouseenter', stopAnimation);
        container.addEventListener('mouseleave', startAnimation);

        startAnimation();

        return () => {
            container.removeEventListener('mouseenter', stopAnimation);
            container.removeEventListener('mouseleave', startAnimation);
            stopAnimation();
        };
    }, []);

    return (
        <div className={classes.servicesSection} ref={ref}>
            <Typography variant="h4" className={classes.sectionTitle} style={{ color: "white" }}>
                <ServicesIcon /> Services We Provide
            </Typography>

            <div className={classes.cardContainer} ref={containerRef}>
                {[...services, ...services].map((service, index) => (
                    <div className={classes.cardItem} key={index}>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                            transition={{ duration: 0.8 }}
                        >
                            <Card className={classes.serviceCard}>
                                <CardContent>
                                    <div className={classes.serviceImageContainer}>
                                        <img src={service.image} alt={service.title} className={classes.serviceImage} />
                                    </div>
                                    <div className={classes.serviceContent}>
                                        <Typography variant="h5" className={classes.serviceTitle}>
                                            {service.title}
                                        </Typography>
                                        <Typography variant="body1" className={classes.serviceDescription}>
                                            {service.description}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </motion.div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServicesSection;